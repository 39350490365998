import { graphql } from 'gatsby';
import React from 'react';

import Layout from '../components/layout';
import QuizComponent from '../components/quizComponent';
import Seo from '../components/seo';

export default ({ data, ...rest }) => (
  <Layout>
    <Seo
      title={`${data.datoCmsLesson.number.charAt(0).toUpperCase() +
        data.datoCmsLesson.number.slice(1)} I Test question`}
      description={data.datoCmsLesson.quiz.question}
    />

    <QuizComponent
      lessonNumber={data.datoCmsLesson.number}
      lessonUrl={data.datoCmsLesson.url}
      quiz={data.datoCmsLesson.quiz}
      lessonType={data.datoCmsLesson.lessonType}
      quizId={data.datoCmsLesson.id}
      totalCount={rest.pageContext.totalLessonsCount}
      quizIndex={rest.pageContext.step || 0}
    />
  </Layout>
)

export const query = graphql`
  query($id: String!) {
    datoCmsLesson(id: { eq: $id }) {
      number
      url
      id
      lessonType
      quiz {
        question
        possibleAnswers {
          text
          isRight
        }
      }
    }
  }
`
