import React from 'react';

import styles from '../sass/components/radio.module.scss';

export default ({ value, name = 'radio', onChange, checked }) => (
  <label className={styles.radio}>
    <input
      type="radio"
      className={styles.radio__input}
      name={name}
      required
      onChange={event => onChange(event)}
      value={value}
      checked={checked}
    />
    <span className={styles.radio__caption}>{value}</span>
    <p className="validation">WRONG</p>
  </label>
)
